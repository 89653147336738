/* eslint-disable react/no-danger */
import { css } from '@emotion/react';
import OfferingBrandingHeader from 'offerings/components/offering-branding-header';
import NvModalPagination from 'org_mentor/components/nv-modal-pagination';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import t from 'react-translate';
import { setSelectedTemplate } from 'redux/actions/course-templates';
import { CourseTemplate } from 'redux/schemas/models/course-templates';
import { InstitutionLogoSize } from 'redux/schemas/models/institution';
import NvIcon from 'shared/components/nv-icon';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { gray5 } from 'styles/global_defaults/colors';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { Template } from '../course-templates-wrapper';

type CourseStartTemplateProps = {
  handleCloseModal: () => void,
  handleSetActiveTab: (activeTab: Template) => void;
};

const CourseTemplatePreview = (props: CourseStartTemplateProps) => {
  const { handleCloseModal, handleSetActiveTab } = props;

  const selectedTemplateData = useSelector((state) => state.app.courseTemplates.selectedTemplate);
  const courseTemplates = useSelector((state) => state.models.courseTemplates);

  const index = courseTemplates.findIndex((item: CourseTemplate) => item.id === selectedTemplateData.id);
  const [currentIndex, setCurrentIndex] = useState<number>(index);
  const [selectedTemplateLocal, setSelectedTemplateLocal] = useState<CourseTemplate>(selectedTemplateData);

  const dispatch = useDispatch();

  const styles = css`
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      margin: auto;
    }

    .preview-img {
      height: 240px;
      min-height: 240px;
      width: 100%;
      background-color: ${gray5};
    }
  `;

  const modalStyles = css`
    &.bs4-modal {
      &.modal-no_dialog {
        .bs4-modal-dialog {
          .bs4-modal-content {
            width: 1040px;
            margin: auto;

            .bs4-modal-header {
              min-height: ${doubleSpacing}px;
              height: ${doubleSpacing}px;

              .bs4-modal-title {
                height: ${doubleSpacing}px;
              }
            }

            .close {
              padding: 0px;
              margin: 0px;
              top: 28px;
              margin-right: -110px;
            }

            .bs4-modal-body {
              padding: 0px ${standardSpacing}px ${standardSpacing}px;
            }

            .preview-body-modal {
              .pagination-buttons {
                top: calc(50vh - 20px);
              }

              .back-button {
                background: none;
                box-shadow: none;
                border: none;
                align-items: center;

                &:active {
                  outline: none;
                }
              }

              .description-container{
                padding: ${doubleSpacing}px ${doubleSpacing * 3}px;
              }
            }
          }
        }
      }
    }
  `;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % courseTemplates.length);
    setSelectedTemplateLocal(courseTemplates[currentIndex]);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + courseTemplates.length) % courseTemplates.length);
    setSelectedTemplateLocal(courseTemplates[currentIndex]);
  };

  const handleSelectModal = () => {
    dispatch(setSelectedTemplate(selectedTemplateLocal));
    handleSetActiveTab(Template.CREATION);
  };

  const PreviewTemplate = () => (
    <div className='preview-body-modal'>
      <div className='d-flex justify-content-between py-2'>
        <div className='d-flex align-items-center'>
          <button type='button' onClick={() => handleSetActiveTab(Template.LISTING)} className='ml-1 d-flex back-button'>
            <NvIcon icon='back' size='small' className='text-white' />
            <div className='text-white text-large-regular font-bold font-weight-bolder ml-2 condensed'>{t.COURSES.COURSE_TEMPLATES.BACK()}</div>
          </button>
        </div>
        <Button
          variant='primary'
          className='text-large-body px-4 py-1'
          onClick={handleSelectModal}
        >
          {t.COURSES.COURSE_TEMPLATES.USE_THIS_TEMPLATE()}
        </Button>
      </div>
      <NvModalPagination
        show
        onNext={handleNext}
        onPrev={handlePrev}
      >
        <div css={styles} className='h-100 bg-gray-7'>
          <div className='top-section'>
            <OfferingBrandingHeader
              value={{
                name: selectedTemplateLocal.name,
                fontColor: 'white',
                logo: {
                  file: undefined,
                  url: selectedTemplateLocal?.institutionLogo,
                  size: InstitutionLogoSize.SMALL,
                },
                brandColor: '',
                background: {
                  file: undefined,
                  url: selectedTemplateLocal?.headerBackground,
                },
              }}
            />
            <div className='description-container'>
              {(selectedTemplateLocal?.classDescription || selectedTemplateLocal?.moreInformation) && (
                <React.Fragment>
                  <div className='text-align-center font-weight-light text-xl'>{t.COURSES.COURSE_TEMPLATES.DESCRIPTION()}</div>
                  <div dangerouslySetInnerHTML={{ __html: (selectedTemplateLocal?.classDescription) }} />
                  <div className='additional-info'>
                    <div dangerouslySetInnerHTML={{ __html: (selectedTemplateLocal?.moreInformation) }} />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </NvModalPagination>
    </div>
  );

  return (
    <React.Fragment>
      <NvModal
        show
        onClose={handleCloseModal}
        type={ModalType.NO_DIALOG}
        width={920}
        fullHeight={false}
        modalStyles={modalStyles}
        body={<PreviewTemplate />}
      />
    </React.Fragment>
  );
};

export default CourseTemplatePreview;
