// Generated from /institutions/:id.json
import { OrgLevelProfileSettings } from 'redux/schemas/models/org-level-profile-settings';
import { OrgLevelMetadataSettings } from 'redux/schemas/models/org-level-metadata-settings';
import { Name } from 'redux/schemas/models/courseFull';
import { SkillTag } from './skill-tag';
import { CredlyConfig } from './credly';
import { EdCastSettings } from './edcast';

export enum InstitutionLogoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface OrgMentorRoleAlias {
  mentor: Name,
  mentee: Name,
}

export interface OrgColor {
  [key: string]: string
}

export interface OrgColors {
  mainColors: OrgColor,
  highlightColors: OrgColor,
}
export interface Institution {
  [x: string]: any;
  id: number;
  name: string;
  logo: string;
  logoSize: string;
  brandColor: string;
  brandBarFontColor: '#fff' | '#000';
  isPremium: boolean;
  courseEnrollmentTypes: string[];
  emailHeader: string;
  emailHeaderStyle: string;
  directUpload: boolean;
  documentSharingProvider: string;
  analyticsIdentifier: string;
  nickname: string;
  host: string;
  favicon: null;
  isInstitutionalAdmin: boolean;
  adminRole: string;
  ssoLogin: boolean;
  identityProviders: any[];
  welcomeEmailEnabled: boolean;
  hasCsod: boolean;
  about: string;
  header: string;
  fontColor: string;
  tos: string;
  accountManagementLink: null;
  enableBasicSsoInfoEdit: boolean;
  enableSearchAllUsers: number;
  allowFileDownloads: boolean;
  currentPeriodStartedAt: Date;
  currentPeriodEndedAt: Date;
  noSeats: number;
  gracePeriod: number;
  unenrollGracePeriod: number;
  notes: string;
  createdAt: Date;
  profileSettings: OrgLevelProfileSettings,
  roleAliases: OrgMentorRoleAlias,
  disableDiscussionEdits?: boolean;
  skillTags: SkillTag[];
  metadataFields: OrgLevelMetadataSettings,
  defaultFilterViews: any[],
  discoveryEnabled?: boolean,
  credlyConfig: CredlyConfig,
  isCredlyEnabled: boolean,
  edcastConfig: EdCastSettings,
  isEdcastEnabled: boolean,
  automatedTranslationEnabled: boolean,
  allowAutomatedTranslation: boolean,
  hasLicenseDashboard: boolean;
  hasViewableDependentLicenses: boolean;
  licenseModel: string;
  speechToTextEnabled: boolean;
  orgColors: OrgColors;
  hasLearnersSelfUnenroll: boolean;
  hasCourseTemplatesEnabled: boolean;
  courseTemplatesCount: number;
}

export interface InstitutionDashboardState {
  courseTypeCounts: CourseTypeCounts,
  countsLoaded: boolean,
  myViewData: any;
  courseHighlight: string | null,
}

export interface JourneysDashboardState {
  journeyTypeCounts: CourseTypeCounts,
  countsLoaded: boolean,
}

export interface CourseTypeCounts {
  myView: number;
  active: number;
  future: number;
  past: number;
  demo: number;
  total: number;
  primary: number;
}

export interface MyViewData {
  id: number,
  type: string,
  name: string,
}

/** A course being currently being cloned  */
export interface CourseCloning {
  id: number;
  nickname: string;
  newDate: Date;
  completed: boolean;
  isPrimary: boolean;
  isCohort: boolean;
  owner: {
    id: number,
    catalogId: string,
  },
}

export interface JourneyCloning {
  id: number;
  name: string;
  releaseDate: string;
  completed: boolean;
  numEnrolled: number;
  owner: {
    id: number,
    catalogId: string,
  };
  requester: {
    admin: boolean;
    firstName: string;
    lastName: string;
    fullName: string;
    id: number;
    initials: string;
    roles: any;
    profilePicture: string;
    userNameForUrl: string;
  }
  settingsHash: {
    catalogId: string;
    name: string;
    releaseDate: string;
    excludeCourses: { id: number }[]
  }
}
