import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllTemplates } from 'redux/actions/course-templates';
import CourseTemplateCreation from './models/course-template-creation';
import CourseTemplateList from './models/course-template-list';
import CourseTemplateLoading from './models/course-template-loading';
import CourseTemplatePreview from './models/course-template-preview';

export enum Template {
  LISTING = 'listing',
  PREVIEW = 'preview',
  CREATION = 'creation',
  LOADING = 'loading',
}

const CourseTemplatesWrapper = ({ handleCloseTemplateModal }) => {
  const [activeTab, setActiveTab] = useState<Template>(Template.LISTING);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);

  const handleSetActiveTab = (passedActiveTab: Template) => {
    setActiveTab(passedActiveTab);
  };

  return (
    <div className='body-text-wrapper pb-6'>
      {activeTab === Template.LISTING && (
        <CourseTemplateList
          handleCloseModal={handleCloseTemplateModal}
          handleSetActiveTab={handleSetActiveTab}
        />
      )}
      {activeTab === Template.PREVIEW && (
        <CourseTemplatePreview
          handleCloseModal={handleCloseTemplateModal}
          handleSetActiveTab={handleSetActiveTab}
        />
      )}
      {activeTab === Template.CREATION && (
        <CourseTemplateCreation
          handleCloseModal={handleCloseTemplateModal}
          handleSetActiveTab={handleSetActiveTab}
        />
      )}
      {activeTab === Template.LOADING && (
        <CourseTemplateLoading
          handleCloseModal={handleCloseTemplateModal}
        />
      )}
    </div>
  );
};


export default CourseTemplatesWrapper;
