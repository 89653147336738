import { createReducer } from '@reduxjs/toolkit';
import { createtemplate, getAllTemplates, setSelectedTemplate } from 'redux/actions/course-templates';
import { initialRootState } from '.';

const courseTemplatesReducer = createReducer(initialRootState, builder => {
  builder
    .addCase(setSelectedTemplate, (state, action) => {
      state.app.courseTemplates.selectedTemplate = action.payload;
    })
    .addCase(getAllTemplates.pending, (state, action) => {
      state.app.courseTemplates.isLoading = true;
    })
    .addCase(getAllTemplates.fulfilled, (state, action) => {
      state.app.courseTemplates.isLoading = false;
      state.models.courseTemplates = action.payload;
    })
    .addCase(createtemplate.fulfilled, (state, action) => {
      // state.app.courseTemplates.isLoading = false;
    });
});

export default courseTemplatesReducer;
