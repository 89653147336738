import { css } from '@emotion/react';
import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import t from 'react-translate';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { createtemplate } from 'redux/actions/course-templates';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { wrapThunkAction } from 'redux/utils';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import pusherService from 'shared/services/pusher-service';
import { Template } from '../course-templates-wrapper';


type CourseStartTemplateProps = {
  handleCloseModal: () => void,
  handleSetActiveTab: (activeTab: Template) => void;
};

const CourseTemplateCreation = (props: CourseStartTemplateProps) => {
  const { handleCloseModal, handleSetActiveTab } = props;
  const selectedTemplate = useSelector((state) => state.app.courseTemplates.selectedTemplate);

  const [error, setError] = useState<boolean>(false);
  const [isCourseCreated, setIsCourseCreated] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState({ id: selectedTemplate?.id, name: selectedTemplate?.name, catalogId: '' });

  const dispatch = useDispatch();
  const flagRef = useRef(false);
  const angularServices = useContext(AngularServicesContext);
  const currentInstitution = useSelector(getCurrentInstitution);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTemplateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const pusherChannel = pusherService.setupChannel(`public-institution-${currentInstitution.id}`);
    if (isCourseCreated) {
      pusherChannel.unbind('course_created');
    } else {
      pusherChannel.bind('course_created', (result) => {
        const catalogId = result.course.catalog_id;
        if (catalogId) {
          setIsCourseCreated(true);
          angularServices.$state.go('course-home', { catalogId });
        }
      });
    }

    return () => {
      // pusherChannel.unbind('course_created');
    };
  }, [angularServices.$state, currentInstitution.id, isCourseCreated]);


  const handleCreateTemplate = () => {
    wrapThunkAction(dispatch(createtemplate({
      courseTemplateId: templateData.id,
      catalogId: templateData.catalogId,
      name: templateData.name,
    }))).then(() => {
      handleSetActiveTab(Template.LOADING);
    }).catch((err) => {
      if (err.payload.code === 'error.catalog_id_taken') {
        setError(true);
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.SOMETHING_WRONG(),
          message: t.FORM.ERROR_TRY_AGAIN(),
        }));
      }
    });
  };

  const styles = css`
    padding: 2rem 8rem 3rem;
  `;

  return (
    <React.Fragment>
      <NvModal
        show
        type={ModalType.DYNAMIC}
        width={600}
        fullHeight={false}
        header={t.COURSES.COURSE_TEMPLATES.CREATE_FROM_TEMPLATE()}
        onClose={handleCloseModal}
        body={(
          <div css={styles}>
            <div>
              <label className='text-small gray-3 mb-0'>{t.COURSES.COURSE_TEMPLATES.COURSE_NAME()}</label>
              <NvTextInput
                name='name'
                ariaLabel='courseName'
                inputClassName='text-medium'
                placeholder={t.COURSES.COURSE_TEMPLATES.TYPE_HERE()}
                required
                value={templateData.name}
                onChange={handleChange}
              />
            </div>
            <div className='mt-4'>
              <label className='text-small gray-3 mb-0'>{t.COURSES.COURSE_TEMPLATES.CATALOG_ID_UNIQUE()}</label>
              <NvTextInput
                name='catalogId'
                ariaLabel='catalogId'
                inputClassName='text-medium'
                placeholder={t.COURSES.COURSE_TEMPLATES.TYPE_HERE()}
                required
                value={templateData.catalogId}
                onChange={handleChange}
                error={error ? { message: t.COURSES.FORM.ERRORS.CATALOG_ID_TAKEN() } as FieldError : undefined}
              />
            </div>
            <div className='mt-8 d-flex justify-content-center'>
              <Button variant='secondary' onClick={handleCloseModal} className='mr-2'>{t.COURSES.COURSE_TEMPLATES.CANCEL()}</Button>
              <Button
                variant='primary'
                disabled={templateData.name === '' || templateData.catalogId === ''}
                onClick={handleCreateTemplate}
              >
                {t.COURSES.COURSE_TEMPLATES.CREATE()}
              </Button>
            </div>
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default CourseTemplateCreation;
