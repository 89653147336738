import { css } from '@emotion/react';
import TemplateCard from 'courses/components/course-template-card/template-card';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from 'react-translate';
import { setSelectedTemplate } from 'redux/actions/course-templates';
import { CourseTemplate } from 'redux/schemas/models/course-templates';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { white } from 'styles/global_defaults/colors';
import { boldFontWeight, headerFontSize, headerLargeLineHeight } from 'styles/global_defaults/fonts';
import { extraLargeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { Template } from '../course-templates-wrapper';

type CourseStartTemplateProps = {
  handleCloseModal: () => void;
  handleSetActiveTab: (activeTab: Template) => void;
};

const CourseTemplateList = (props: CourseStartTemplateProps) => {
  const { handleSetActiveTab, handleCloseModal } = props;

  const courseTemplates = useSelector((state) => state.models.courseTemplates);
  const courseTemplatesLoading = useSelector((state) => state.app.courseTemplates.isLoading);
  const currentInstitution = useSelector(getCurrentInstitution);

  const dispatch = useDispatch();

  const styles = css`
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: auto;
    }

    .grid-item {
      padding: ${standardSpacing}px;
      text-align: center;
    }
  `;

  const modalStyles = css`
    &.bs4-modal {
      &.modal-dynamic {
        .bs4-modal-dialog {
          .bs4-modal-content {
            .bs4-modal-header {
              min-height: 90px;
              height: 90px;

              .bs4-modal-title {
                height: ${extraLargeSpacing}px;
                margin: ${standardSpacing}px !important;
                justify-content: normal;
              }

              .modal-title-content {
                text-align: left;
                font-weight: ${boldFontWeight};
                color: ${white};
                font-size: ${headerFontSize}px;
                line-height: ${headerLargeLineHeight};
              }

              .close {
                padding: 0px;
                margin: ${standardSpacing}px ${quarterSpacing}px;
                color: ${white};
              }
            }
          }
        }
      }
    }
  `;

  const handleSelectModal = (selectedTemplateData: CourseTemplate) => {
    dispatch(setSelectedTemplate(selectedTemplateData));
    handleSetActiveTab(Template.CREATION);
  };

  const handlePreviewModal = (selectedTemplateData: CourseTemplate) => {
    dispatch(setSelectedTemplate(selectedTemplateData));
    handleSetActiveTab(Template.PREVIEW);
  };

  return (
    <React.Fragment>
      <NvModal
        show
        onClose={handleCloseModal}
        type={ModalType.DYNAMIC}
        width={920}
        fullHeight={false}
        header={t.COURSES.COURSE_TEMPLATES.START_FROM_A_TEMPLATE()}
        headerBgColor={currentInstitution.brandColor}
        modalStyles={modalStyles}
        body={(
          <div css={styles}>
            <div className='grid-container'>
              {courseTemplatesLoading
                ? Array.from({ length: 4 }).map((_) => (
                  <div className='grid-item'>
                    <TemplateCard
                      isLoading
                    />
                  </div>
                ))
                : courseTemplates.map((templateData: CourseTemplate) => (
                  <div key={templateData.id} className='grid-item'>
                    <TemplateCard
                      cardImage={templateData.headerBackground}
                      cardHeader={templateData.name}
                      cardDescriptionText={templateData.classDescription}
                      moreInformationText={templateData.moreInformation}
                      handlePreview={() => handlePreviewModal(templateData)}
                      handleSelect={() => handleSelectModal(templateData)}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default CourseTemplateList;
