/* eslint-disable react/no-danger */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import NvIcon from 'shared/components/nv-icon';
import { black, gray5, gray6, hexToRgbaString, kelp } from 'styles/global_defaults/colors';
import { doubleSpacing, extraLargeSpacing, halfSpacing, largeSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

type TemplateCardProps = {
  isLoading?: boolean;
  cardImage?: string;
  cardHeader?: string;
  cardDescriptionText?: string;
  moreInformationText?: string;
  handlePreview?: () => void;
  handleSelect?: () => void;
};

const extractPTagContent = (htmlString: string): string => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');
  return htmlDoc.body && (htmlDoc.body.innerText !== 'null') ? htmlDoc.body.innerText : '';
};

const TemplateCard = (props: TemplateCardProps) => {
  const {
    isLoading = false,
    cardImage,
    cardHeader,
    cardDescriptionText,
    moreInformationText,
    handlePreview,
    handleSelect,
  } = props;
  const [isHover, setIsHover] = useState(false);

  const styles = css`
    .card-container {
      width: 400px;
      min-height: 411px;
      padding: ${standardSpacing}px;
      border: 1px solid ${gray6};
      box-shadow: 0px ${halfSpacing}px ${halfSpacing}px 0px ${hexToRgbaString(black, 0.1)};

      .card-img {
        min-height: ${tripleSpacing * 3}px;
        max-height: ${tripleSpacing * 3}px;
        width: -webkit-fill-available;
        background-color: ${gray5};
      }

      .mh-3rem {
        min-height: ${largeSpacing}px;
      }

      .mh-4rem {
        min-height: ${doubleSpacing}px;
      }

      .mh-5rem {
        min-height: ${extraLargeSpacing}px;
      }

      .twoline-ellipsis {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .lc-1 {
        -webkit-line-clamp: 1;
      }

      .more-info-text-color {
        background-color: ${hexToRgbaString(kelp, 0.1)};
      }

      .button-container {
        gap: ${halfSpacing}px;
        justify-content: center;
        padding: 7px;
      }

      .ct-loading-wrapper {
        .placeholder-bar {
          height: ${halfSpacing}px;
        }
      }
    }
  `;

  const renderCardImage = () => {
    if (isLoading) {
      return <div className='card-img loading' />;
    }
    if (cardImage) {
      return <img src={cardImage} alt='img' className='card-img' />;
    }
    return <div className='card-img' />;
  };

  const renderHeader = () => (
    <div className='d-flex'>
      <NvIcon icon='admin-template' size='smallest' className='text-kelp' />
      <span className='font-weight-bolder text-small text-kelp ml-1'>
        {t.COURSES.COURSE_TEMPLATES.TEMPLATE()}
      </span>
    </div>
  );

  const renderContent = () => (
    <React.Fragment>
      <span className='text-xl font-weight-bolder mt-2 twoline-ellipsis lc-1 text-align-left'>
        {cardHeader}
      </span>
      <span
        className='text-medium my-2 twoline-ellipsis text-align-left mh-4rem'
        dangerouslySetInnerHTML={{ __html: extractPTagContent(cardDescriptionText) }}
      />
    </React.Fragment>
  );

  const renderButtons = () => (
    <div className='d-flex button-container'>
      <Button variant='secondary' onClick={handlePreview}>
        {t.COURSES.COURSE_TEMPLATES.PREVIEW()}
      </Button>
      <Button variant='primary' onClick={handleSelect}>
        {t.COURSES.COURSE_TEMPLATES.SELECT()}
      </Button>
    </div>
  );

  const renderMoreInfo = () => (
    <div className='p-2 more-info-text-color text-align-left'>
      <span
        className='text-small font-weight-bold twoline-ellipsis mh-3rem'
        dangerouslySetInnerHTML={{ __html: extractPTagContent(moreInformationText) }}
      />
    </div>
  );

  return (
    <div css={styles}>
      <div
        className='card-container'
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {renderCardImage()}
        <div className='mt-4'>
          {renderHeader()}
          {isLoading
            ? <LoadingPlaceholder loaderType={LoaderType.HALF} className='mt-5 ct-loading-wrapper' />
            : renderContent()}
          <div className='mh-5rem'>
            {!isLoading
              && (isHover ? renderButtons() : moreInformationText && renderMoreInfo())}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
